<template>
  <v-container>
    <v-form v-model="formValid">
      <v-row>
        <v-col cols="12">
          <h1 class="display-1 text-center">Globální nastavení topování</h1>
        </v-col>
      </v-row>
      <!-- START promo inputs ------------------------------------------------------------------------- -->
      <v-row>
        <v-col>
          <v-text-field
            label="Cena příhozu"
            dense
            :step="1/*0.01*/"
            class="rounded-0"
            outlined
            type="number"
            v-model.number="form.promoBidPrice"
            min="0"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Počet topovaných pozic ve vyhledávání"
            dense
            outlined
            class="rounded-0"
            type="number"
            v-model.number="form.promoFilteringPositions"
            min="0">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Počet topovaných pozic na hlavní stránce"
            dense
            outlined
            class="rounded-0"
            type="number"
            v-model.number="form.promoMainPagePositions"
            min="0">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Cena za zobrazení"
            dense
            outlined
            class="rounded-0"
            type="number"
            :step="1/*0.01*/"
            v-model.number="form.promoViewPrice"
            min="0">
          </v-text-field>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="8">
              <v-slider
                persistent-hint
                hint="Velikost jednotky pro nákup"
                dense
                min="1"
                v-model="form.promoViewUnitSize"
                max="10000"
              ></v-slider>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.promoViewUnitSize"
                label="Kč"
                :rules="[validation.required, validation.greaterThan(0), validation.lessThan(10001)]"
                outlined
                dense
                type="number"
                class="rounded-0"
                hide-details>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- START ads inputs ----------------------------------------------------------------------- -->
      <v-row>
        <v-col cols="12">
          <h1 class="display-1 text-center">Globální nastavení inzerce</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Cena za měsíc"
            dense
            :step="1/*0.01*/"
            class="rounded-0"
            :rules="[validation.required]"
            outlined
            type="number"
            v-model.number="form.advertisingMonthPrice"
            min="0"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="Cena za rok"
            dense
            outlined
            class="rounded-0"
            type="number"
            :rules="[validation.required]"
            v-model.number="form.advertisingYearPrice"
            min="0">
          </v-text-field>
        </v-col>
      </v-row>
      <!-- END inputs ------------------------------------------------------------------------------ -->
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            color="primary"
            class="rounded-0 justify-end"
            :disabled="!formValid"
            :loading="fetchingData"
            @click="save"
            >
            Uložit
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Promo',
  data () {
    return {
      formValid: true,
      form: {
        advertisingMonthPrice: 0,
        advertisingYearPrice: 0,
        promoBidPrice: 0,
        promoMainPagePositions: 1,
        promoFilteringPositions: 1,
        promoViewPrice: 0,
        promoViewUnitSize: 1
      },
      fetchingData: false
    }
  },
  methods: {
    save () {
      this.fetchingData = true
      this.$http.post('/admin/global-advertising-settings', this.form)
        .then(res => {
          delete res.data.id
          delete res.data.createdAt
          delete res.data.updatedAt
          this.form = {
            ...res.data
          }
          this.setAlerts([{
            message: 'Data byla uložena',
            type: 'success'
          }])
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  created () {
    this.$http.get('/admin/global-advertising-settings')
      .then(res => {
        delete res.data.id
        delete res.data.createdAt
        delete res.data.updatedAt
        this.form = {
          ...res.data
        }
      })
  }
}
</script>

<style scoped>

</style>
